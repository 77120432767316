import { Global } from "@emotion/core";
import React from "react";
import { arrayOf, exact, string, node, oneOf } from "prop-types";
import { graphql } from "gatsby";

import {
	MenuItemPropType,
	HeaderPropTypes,
	LoginResortsPropTypes,
} from "~/types";
import Wobble from "Components/Wobble";
import { EMPTY_ARR } from "Services/constants/empty";
import Header from "./elements/Header";
import Footer from "./elements/Footer";
import Modal from "./elements/Modal";
import ScrollDownPrompt from "./elements/ScrollDownPrompt";
import Styles from "./styles.css";
import "./fonts.css";

const Wrapper = ({
	children,
	header,
	footer,
	lang,
	legal,
	loginResorts,
	pageType,
	products,
}) => {
	return (
		<>
			<Global styles={Styles.app} />
			<Wobble />
			<Header
				header={header}
				lang={lang}
				loginResorts={loginResorts}
				pageType={pageType}
				products={products}
				productsMenu={footer.productsMenu}
				signInMenu={footer.signInMenu}
			/>
			<ScrollDownPrompt />
			<div css={Styles.mainWrapper}>
				<main css={Styles.main}>{children}</main>
			</div>
			<Footer {...footer} lang={lang} legal={legal} products={products} />
			<Modal />
		</>
	);
};

Wrapper.defaultProps = {
	legal: EMPTY_ARR,
	pageType: "home",
	products: EMPTY_ARR,
};

Wrapper.propTypes = {
	children: node.isRequired,
	pageType: oneOf(["home", "plain", "product"]),
	lang: string,
	footer: exact({
		body: string,
		copyright: string,
		productsMenu: string,
		legalMenu: string,
		signInMenu: string,
	}),
	header: HeaderPropTypes,
	loginResorts: LoginResortsPropTypes,
	legal: arrayOf(MenuItemPropType),
	products: arrayOf(MenuItemPropType),
};

export default Wrapper;

export const query = graphql`
	fragment HeaderQuery on ContentfulHeader {
		backButton
		resortGuestsMenu
		resortGuestsStrap
		resortGuestsBody
		resortGuestsButton
		instructorsMenu
		instructorsStrap
		instructorsBody
		instructorsButton
		havingTroubleMenu
		havingTroubleStrap
		havingTroubleBody
		havingTroubleButton
		havingTroubleThankYou
		mountainViewStrap
		bookingStrap
		classManagementStrap
		oneStopShopMenu
	}
	fragment FooterQuery on ContentfulFooter {
		body
		copyright
		productsMenu
		legalMenu
		signInMenu
	}
	fragment ProductsMenuQuery on ContentfulProduct {
		title
		slug
	}
	fragment PlainPagesMenuQuery on ContentfulPagePlain {
		title
		slug
	}
`;
