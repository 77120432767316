import styling from "Services/styling";

export default {
	wrapper: {
		position: "absolute",
		top: "100%",
		right: 0,
		width: 840,
		backgroundColor: styling.colors.backgroundDefault,
		display: "flex",
		...styling.mixins.borderRadiuses(0, 4, 4, 0),
		...styling.mixins.boxShadow(0, 0, 24, 0, styling.colors.boxShadow),
	},
	products: {
		flexGrow: 1,
		display: "flex",
		flexDirection: "column",
	},
	bookings : {
		flexGrow: 1,
		...styling.mixins.transition("background-color"),
		...styling.mixins.padding(60, 75, 30),
		":hover": {
			backgroundColor: styling.colors.backgroundCircle,
		},
	},
	mountainView: {
		flexGrow: 1,
		...styling.mixins.transition("background-color"),
		...styling.mixins.padding(60, 75, 30),
		":hover": {
			backgroundColor: styling.colors.backgroundCircle,
		},
	},
	classManagement: {
		flexGrow: 1,
		...styling.mixins.transition("background-color"),
		...styling.mixins.padding(30, 75, 60),
		...styling.mixins.borderRadiuses(0, 0, 4, 0),
		":hover": {
			backgroundColor: styling.colors.backgroundCircle,
		},
	},
	suite: {
		flexGrow: 1,
		width: "61%",
		...styling.mixins.transition("background-color"),
		...styling.mixins.padding(60, 75),
		...styling.mixins.borderRadiuses(0, 4, 0, 0),
		":hover": {
			backgroundColor: styling.colors.backgroundCircle,
		},
	},
	suiteNav: {
		display: "flex",
		...styling.mixins.margin(20, 0, 0),
		"> :last-of-type": {
			...styling.mixins.margin(0, 0, 0, 40),
		},
	},
	icon: {
		height: 50,
		width: 50,
		position: "absolute",
		left: 0,
		top: "50%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		...styling.mixins.margin(-25, 0, 0),
	},
	menuHeader: {
		fontSize: "2.4rem",
		lineHeight: "5rem",
		position: "relative",
		...styling.mixins.padding(0, 0, 0, 70),
		...styling.mixins.margin(0, 0, 20),
	},
	menuHeaderLinkActive: {
		color: styling.colors.linkInteract,
	},
	menuHeaderLink: {
		fontSize: "2.4rem",
		lineHeight: "2.4rem",
		position: "relative",
		display: "block",
		color: styling.colors.linkDefault,
		...styling.mixins.padding(0, 0, 0, 70),
		...styling.mixins.margin(0, 0, 20),
		...styling.mixins.transition("color"),
		":hover, :focus, :active": {
			color: styling.colors.linkInteract,
		},
	},
	menuStrap: {
		fontSize: "1.4rem",
		lineHeight: "1.8rem",
		color: styling.colors.textQuadrinary,
	},
	menuLink: {
		display: "block",
		color: styling.colors.linkDefault,
		whiteSpace: "nowrap",
		...styling.mixins.padding(6, 0),
		...styling.mixins.transition("color"),
		":hover, :focus, :active": {
			color: styling.colors.linkInteract,
		},
	},
	menuLinkActive: {
		color: styling.colors.linkInteract,
	},
};
