import { Link } from "gatsby";
import React from "react";
import { arrayOf, string } from "prop-types";

import { HeaderPropTypes, MenuItemPropType } from "~/types";
import { Maps, ShadowShadow, Circles, CalendarMoney } from "Components/Icons";
import Styles from "./styles.css";

const ProductMenu = (props) => {
	const { header, lang, products } = props;
	const longHalfOfProducts = Math.ceil(products.length / 2);
	return (
		<div css={Styles.wrapper}>
			<div css={Styles.products}>
				<div css={Styles.bookings}>
					<Link
						activeStyle={Styles.menuHeaderLinkActive}
						css={Styles.menuHeaderLink}
						to={`/${lang}/product/bookings/`}>
						<div css={Styles.icon}>
							<CalendarMoney />
						</div>
						Bookings
					</Link>
					<div css={Styles.menuStrap}>{header.bookingStrap}</div>
				</div>
				<div css={Styles.mountainView}>
					<Link
						activeStyle={Styles.menuHeaderLinkActive}
						css={Styles.menuHeaderLink}
						to={`/${lang}/product/mountain-view/`}>
						<div css={Styles.icon}>
							<Maps />
						</div>
						Mountain View
					</Link>
					<div css={Styles.menuStrap}>{header.mountainViewStrap}</div>
				</div>
			</div>
			<div css={Styles.suite}>
				<div css={Styles.menuHeader}>
					<div css={Styles.icon}>
						<Circles />
					</div>
					{header.oneStopShopMenu}
				</div>
				<nav css={Styles.suiteNav}>
					<div>
						{products.slice(0, longHalfOfProducts).map((productItem) => (
							<Link
								key={productItem.node.slug}
								activeStyle={Styles.menuLinkActive}
								css={Styles.menuLink}
								to={`/${lang}/product/${productItem.node.slug}/`}>
								{productItem.node.title}
							</Link>
						))}
					</div>
					<div>
						{products.slice(longHalfOfProducts).map((productItem) => (
							<Link
								key={productItem.node.slug}
								activeStyle={Styles.menuLinkActive}
								css={Styles.menuLink}
								to={`/${lang}/product/${productItem.node.slug}/`}>
								{productItem.node.title}
							</Link>
						))}
					</div>
				</nav>
			</div>
		</div>
	);
};

ProductMenu.propTypes = {
	header: HeaderPropTypes,
	lang: string,
	products: arrayOf(MenuItemPropType),
};

export default ProductMenu;
