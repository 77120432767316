import React from "react";

import styling from "Services/styling";

export default () => (
	<svg viewBox="0 0 68 42">
		<path
			d="M43 20C35.272 20 29 26.272 29 34C29 41.728 35.272 48 43 48C50.728 48 57 41.728 57 34C57 26.272 50.728 20 43 20ZM44.974 42.526V45.2H41.236V42.498C38.842 41.994 36.812 40.454 36.658 37.738H39.402C39.542 39.208 40.55 40.356 43.112 40.356C45.856 40.356 46.472 38.984 46.472 38.13C46.472 36.968 45.856 35.876 42.734 35.134C39.262 34.294 36.882 32.866 36.882 29.996C36.882 27.588 38.828 26.02 41.236 25.502V22.8H44.974V25.53C47.578 26.16 48.88 28.134 48.964 30.276H46.22C46.15 28.722 45.324 27.658 43.112 27.658C41.012 27.658 39.752 28.61 39.752 29.954C39.752 31.13 40.662 31.9 43.49 32.628C46.318 33.356 49.342 34.574 49.342 38.102C49.328 40.664 47.41 42.064 44.974 42.526Z"
			fill={styling.colors.primary}
		/>
		<path
			d="M26.98 21.06L24.86 18.938L15.12 28.68L10.9 24.46L8.78 26.58L15.12 32.92L26.98 21.06ZM4 12H32V8H4V12ZM4 36H32V16H4V36ZM32 4H30V0H26V4H10V0H6V4H4C1.8 4 0 5.8 0 8V36C0 38.198 1.8 40 4 40H32C34.2 40 36 38.198 36 36V8C36 5.8 34.2 4 32 4Z" 
			fill={styling.colors.iconSecondary}
			fillRule="evenodd"
		/>
	</svg>
);
